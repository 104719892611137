import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Faiz A. Farooqui`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Software Engineer from Bengaluru, India.`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://github.com/faizahmedfarooqui"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/badge/-faizahmedfarooqui-white?style=flat-square&logo=GitHub&logoColor=black&link=https://github.com/faizahmedfarooqui",
          "alt": "GitHub Badge"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/faizahmedfarooqui/"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/badge/-faizahmedfarooqui-blue?style=flat-square&logo=Linkedin&logoColor=white&link=https://www.linkedin.com/in/faizahmedfarooqui/",
          "alt": "Linkedin Badge"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://blog.faizahmed.in/"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/badge/-blog.faizahmed.in-darkblue?style=flat-square&logo=Hashnode&logoColor=white&link=http://blog.faizahmed.in/",
          "alt": "Hashnode Badge"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "mailto:contact@faizahmed.in"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/badge/-contact@faizahmed.in-c14438?style=flat-square&logo=Gmail&logoColor=white&link=mailto:contact@faizahmed.in",
          "alt": "Gmail Badge"
        }}></img></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      