import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Express Typescript Postgres" link="https://www.github.com/geekyants/express-typescript-postgres" bg="linear-gradient(to right, #ffafbd 0%, #ffc3a0 100%)" type="github" mdxType="ProjectCard">
  An API Boilerplate for Node.js, Express.js & PostgresSQL. We've tried to move most of the logic base from NodeJS to Postgres' DB functions.
    </ProjectCard>
    <ProjectCard title="Express Typescript" link="https://www.github.com/geekyants/express-typescript" bg="linear-gradient(to right, #2193b0 0%, #6dd5ed 100%)" type="github" mdxType="ProjectCard">
  A NodeJS framework built for Web & API servers using NodeJS, Typescript & various things that complete a frameworks requirement with Basic Authentication Logic already built.
    </ProjectCard>
    <ProjectCard title="GA-WDIO" link="https://github.com/GeekyAnts/ga-wdio" type="github" bg="linear-gradient(to right, #cc2b5e 0%, #753a88 100%)" mdxType="ProjectCard">
  An automation testing CLI tool that creates the tests project for Web, API & Mobile apps and runs tools like geckodriver, chromedriver & appium internally for various stacks, browsers, platforms & modes.
    </ProjectCard>
    <ProjectCard title="NodeJS without NPM" link="https://github.com/faizahmedfarooqui/nodejs" type="github" bg="linear-gradient(to right, #2b5876 0%, #4e4376 100%)" mdxType="ProjectCard">
  A Web & API server with a CLI tool built using low-level NodeJS’s APIs; i.e. without using any NPM packages.
    </ProjectCard>
    <ProjectCard title="email2webhook" link="https://email2webhook.com/" bg="linear-gradient(to right, #ff9966 0%, #ff5e62 100%)" type="website" mdxType="ProjectCard">
  Email to REST API. Send emails to a RESTful API. The webhook integrations let you post your email to any HTTP endpoint on the Internet.
    </ProjectCard>
    <ProjectCard title="NativeBase Market" link="https://market.nativebase.io/" bg="linear-gradient(to right, #141e30 0%, #243b55 100%)" type="website" mdxType="ProjectCard">
  100+ responsive, accessible, dark mode compatible UI screens that work on web and native platforms to build your app upto 2x faster.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      