import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I'm passionate about working on open source products & building thriving communities around them.`}</p>
    </blockquote>
    <p>{`Experienced & result-driven IT Infrastructure Software Engineer, with strong experience in providing strategy, planning and implementation for various projects and companies. A sophisticated understanding of the analysis, design, and implementation of every aspect of the concerned project, be it the backend, frontend, mobile devices, testing and devops.`}</p>
    <p>{`You can read more about my biography, experience, skills, education and much more in the PDF attached bellow:`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      